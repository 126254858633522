import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../layouts/layout'
import Container from '../components/container'
import SEO from '../components/seo'
import Hero from '../components/Hero/Hero'
import Pagination from '../components/Pagination/Pagination'
import Card from '../components/Card/Card'

const PostGrid = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 2rem;

    @media (min-width: 769px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const PostPagination = styled(Pagination)`
    justify-content: flex-end;
`
export default function BlogPage({ data, pageContext }) {
    const posts = data.allWpPost.edges.map(({ node: post }) => post)
    const pageTitle =
        pageContext.pageNumber > 0
            ? `News - Page ${pageContext.humanPageNumber}`
            : 'News'

    return (
        <Layout>
            <SEO title={pageTitle} />
            <Hero image={data.heroImage} title="NEWS ROOM" />
            <Container>
                <PostGrid>
                    {posts.map(post => (
                        <Card
                            key={post.id}
                            title={post.title}
                            image={
                                post.featuredImage &&
                                post.featuredImage.node.localFile
                                    .childImageSharp.fluid
                            }
                            linkHref={post.link}
                        />
                    ))}
                </PostGrid>
                <PostPagination pageContext={pageContext} pathPrefix="/" />
            </Container>
        </Layout>
    )
}

BlogPage.propTypes = {
    data: PropTypes.shape({
        allWpPost: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
    pageContext: PropTypes.shape({
        currentPage: PropTypes.number,
        numPages: PropTypes.number,
    }),
}

export const pageQuery = graphql`
    query IndexQuery($limit: Int!, $skip: Int!) {
        allWpPost(
            sort: { fields: date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...PostListFields
                }
            }
        }
        heroImage: file(relativePath: { eq: "news-bg@2x.png" }) {
            ...heroImage
        }
    }
`
