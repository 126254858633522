import React from 'react'
import Link from '../Link/Link'
import styled from '@emotion/styled'
import { PrevIcon, NextIcon } from './PaginationIcons'

const Nav = styled.nav`
    display: flex;
`

const Button = styled(Link)`
    border: 1px solid;
    border-radius: 5px;
    display: inline-block;
    min-width: 3rem;
    margin-right: 0.5em;
    text-decoration: none;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    background-color: ${props => props.active && 'var(--color-orange)'};
    color: ${props => (props.active ? '#fff' : '#7B818E')};
    border-color: ${props =>
        props.active ? 'var(--color-orange)' : '#DDDDDD;'};

    svg {
        height: 1rem;
        width: auto;
        opacity: ${props => props.disabled && '0.4'};
    }
`

const Pagination = ({ pageContext, pathPrefix, className }) => {
    const {
        previousPagePath,
        nextPagePath,
        numberOfPages,
        pageNumber,
    } = pageContext

    return (
        <Nav role="navigation" className={className}>
            <Button disabled={!previousPagePath} to={previousPagePath}>
                <PrevIcon />
            </Button>
            {Array.from({ length: numberOfPages }).map((_, idx) => (
                <Button
                    key={`pagination-number-${idx + 1}`}
                    to={`/news${idx !== 0 ? `/${idx + 1}` : ''}`}
                    active={idx === pageNumber}
                >
                    {idx + 1}
                </Button>
            ))}

            <Button disabled={!nextPagePath} to={nextPagePath}>
                <NextIcon />
            </Button>
        </Nav>
    )
}

export default Pagination
